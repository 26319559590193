export const staffColumns = [
  {
    title: '序号',
    dataIndex: 'id1',
    key: 'id1',
    align: 'left',
    width: 117,
    slots: { customRender: 'id1' }
  },
  {
    title: '姓名',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    slots: { customRender: 'staffName' },
    ellipsis: true,
    width: 225
  },
  {
    title: '账号',
    dataIndex: 'account',
    key: 'account',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'account' },
    width: 174
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'mobile' },
    width: 172
  },
  {
    title: '所属组织',
    dataIndex: 'departmentName',
    key: 'departmentName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'departmentName' },
    width: 172
  },
  {
    title: '授权角色',
    dataIndex: 'roleVOList',
    key: 'roleVOList',
    slots: { customRender: 'roleVOList' },
    width: 250
  },
  {
    title: '指纹录入',
    dataIndex: 'fingerprintEntry',
    key: 'fingerprintEntry',
    align: 'left',
    width: 144,
    ellipsis: true,
    slots: { customRender: 'fingerprintEntry' }
  },
  // {
  //   title: '操作',
  //   dataIndex: 'action',
  //   key: 'action',
  //   align: 'left',
  //   slots: { customRender: 'action' },
  //   fixed: 'right',
  //   width: 124
  // }
]
export const childColumns = [
  {
    title: '序号',
    dataIndex: 'id1',
    key: 'id1',
    align: 'left',
    width: 117,
    slots: { customRender: 'id1' }
  },
  {
    title: '姓名',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    slots: { customRender: 'staffName' },
    ellipsis: true,
    width: 225
  },
  {
    title: '账号',
    dataIndex: 'account',
    key: 'account',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'mobile' },
    width: 174
  },
  {
    title: '所属组织',
    dataIndex: 'departmentName',
    key: 'departmentName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'departmentName' },
    width: 172
  },
  {
    title: '指纹录入',
    dataIndex: 'fingerprintEntry',
    key: 'fingerprintEntry',
    align: 'left',
    width: 144,
    ellipsis: true,
    slots: { customRender: 'fingerprintEntry' }
  }
]

export const staffColumnsSort = [
  {
    title: '',
    dataIndex: 'sort',
    key: 'sort',
    align: 'left',
    width: 92,
    slots: { customRender: 'sort' }
  },
  {
    title: '序号',
    dataIndex: 'id1',
    key: 'id1',
    align: 'left',
    width: 117,
    slots: { customRender: 'id1' }
  },
  {
    title: '姓名',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    slots: { customRender: 'staffName' },
    ellipsis: true,
    width: 225
  },
  {
    title: '账号',
    dataIndex: 'account',
    key: 'account',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'mobile' },
    width: 174
  },
  {
    title: '所属组织',
    dataIndex: 'departmentName',
    key: 'departmentName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'departmentName' },
    width: 172
  },
  {
    title: '指纹录入',
    dataIndex: 'fingerprintEntry',
    key: 'fingerprintEntry',
    align: 'left',
    width: 144,
    ellipsis: true,
    slots: { customRender: 'fingerprintEntry' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 124
  }
]
export const childSortColumns = [
  {
    title: '',
    dataIndex: 'sort',
    key: 'sort',
    align: 'left',
    width: 92,
    slots: { customRender: 'sort' }
  },
  {
    title: '序号',
    dataIndex: 'id1',
    key: 'id1',
    align: 'left',
    width: 117,
    slots: { customRender: 'id1' }
  },
  {
    title: '姓名',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    slots: { customRender: 'staffName' },
    ellipsis: true,
    width: 225
  },
  {
    title: '账号',
    dataIndex: 'account',
    key: 'account',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'mobile' },
    width: 174
  },
  {
    title: '所属组织',
    dataIndex: 'departmentName',
    key: 'departmentName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'departmentName' },
    width: 172
  },
  {
    title: '授权角色',
    dataIndex: 'roleVOList',
    key: 'roleVOList',
    slots: { customRender: 'roleVOList' },
    width: 250
  },
  {
    title: '指纹录入',
    dataIndex: 'fingerprintEntry',
    key: 'fingerprintEntry',
    align: 'left',
    width: 144,
    ellipsis: true,
    slots: { customRender: 'fingerprintEntry' }
  }
]

export const departmentColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '部门名称',
    dataIndex: 'departmentName',
    key: 'departmentName',
    ellipsis: true,
    align: 'left',
    slots: { customRender: 'departmentName' },
    width: 200
  },
  {
    title: '上级部门',
    dataIndex: 'parentDeptName',
    key: 'parentDeptName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'parentDeptName' },
    width: 100
  },
  {
    title: '部门级别',
    dataIndex: 'level',
    key: 'level',
    align: 'left',
    slots: { customRender: 'level' },
    width: 100
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 150
  }
]

export const processColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程名称',
    dataIndex: 'flowName',
    key: 'flowName',
    slots: { customRender: 'flowName' },
    ellipsis: true,
    width: 100
  },
  {
    title: '创建人',
    dataIndex: 'foundName',
    key: 'foundName',
    slots: { customRender: 'flowName' },
    ellipsis: true,
    width: 100
  },
  {
    title: '流程类型',
    dataIndex: 'flowType',
    key: 'flowType',
    slots: { customRender: 'flowType' },
    width: 100
  },
  // {
  //   title: '适用范围',
  //   dataIndex: 'flowScope',
  //   key: 'flowScope',
  //   slots: { customRender: 'flowScope' },
  //   ellipsis: true,
  //   width: 100
  // },
  // {
  //   title: '业务类型',
  //   dataIndex: 'businessType',
  //   key: 'businessType',
  //   slots: { customRender: 'businessType' },
  //   width: 100
  // },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 150
  }
]

export const rolePeopleColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '姓名',
    dataIndex: 'dataName',
    key: 'dataName',
    slots: { customRender: 'dataName' },
    width: 100
  },
  {
    title: '部门',
    dataIndex: 'departmentNames',
    key: 'departmentNames',
    slots: { customRender: 'departmentNames' },
    width: 140
  },
  // {
  //   title: '职务',
  //   dataIndex: 'dep',
  //   key: 'dep',
  //   slots: { customRender: 'dep' },
  //   width: 100
  // },
  {
    title: '当前数据范围',
    dataIndex: 'rangData',
    key: 'rangData',
    slots: { customRender: 'rangData' },
    width: 200
  },
  {
    title: '设置管理/查看范围',
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' },
    width: 150
  }
]

export const roleColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '角色名称',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    slots: { customRender: 'name' },
    width: 200
  },
  {
    title: '角色类型',
    dataIndex: 'roleType',
    key: 'roleType',
    slots: { customRender: 'roleType' },
    width: 150
  },
  {
    title: '角色说明',
    dataIndex: 'roleExplain',
    key: 'roleExplain',
    ellipsis: true,
    slots: { customRender: 'roleExplain' },
    width: 100
  },
  {
    title: '授权数',
    dataIndex: 'grantNumber',
    key: 'grantNumber',
    slots: { customRender: 'grantNumber' },
    width: 100
  },
  {
    title: '授权人员',
    dataIndex: 'staffNames',
    key: 'staffNames',
    slots: { customRender: 'staffNames' },
    width: 100
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 120
  }
]

export const logColumns = [
  // {
  //   title: '序号',
  //   customRender: ({ text, record, index }) => {
  //     return `${index + 1}`
  //   },
  //   width: 100
  // },
  {
    title: '序号',
    dataIndex: 'idIndex',
    key: 'idIndex',
    slots: { customRender: 'idIndex' },
    width: 100
  },
  {
    title: '操作人',
    dataIndex: 'operatorName',
    key: 'operatorName',
    ellipsis: true,
    slots: { customRender: 'operatorName' },
    width: 160
  },
  {
    title: '所在部门',
    dataIndex: 'departmentName',
    key: 'departmentName',
    ellipsis: true,
    slots: { customRender: 'departmentName' },
    width: 160
  },
  {
    title: '操作时间',
    dataIndex: 'createTime',
    key: 'createTime',
    slots: { customRender: 'createTime' },
    width: 200
  },
  {
    title: 'IP地址',
    dataIndex: 'ip',
    key: 'ip',
    slots: { customRender: 'ip' },
    width: 150
  },
  {
    title: '操作模块',
    dataIndex: 'moduleName',
    key: 'moduleName',
    slots: { customRender: 'moduleName' },
    width: 150
  },
  {
    title: '操作内容',
    dataIndex: 'content',
    key: 'content',
    slots: { customRender: 'content' },
    width: 251
  }
]
